import React from "react";
import * as styles from "./Services.module.css";
import SingleService from "./SingleService";

const Services = () => {
 let i = 0;
 const serviceElements = services.map((service) => (
  <SingleService
   key={i++}
   heading={service.heading}
   description={service.description}
   link={service.link}
  />
 ));

 return (
  <div className={styles.container}>
   <h1 className={styles.heading}>Våra Tjänster</h1>
   <div>{serviceElements}</div>
  </div>
 );
};

export default Services;

const services = [
 {
  heading: "Barn klippning upp till 10 år",
  description: "30 minuter, 380 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/barn-klippning-fr%C3%A5n-3-%C3%A5r-upp-till-10-%C3%A5r-1248650",
 },
 {
  heading: "Putsning",
  description: "30 minuter, 380 kr",
  link: "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/putsning--1248658",
 },
 {
  heading: "Färg & klippning",
  description: "120 minuter, 2200 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/f%C3%A4rghela-h%C3%A5ret-l%C3%A5ngt-h%C3%A5r-in-kl-1248657",
 },
 {
  heading: "Utväxtfärg",
  description: "90 minuter, 1650 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/f%C3%A4rg-botten-utv%C3%A4xt--1454341",
 },
 {
  heading: "Balayage",
  description: "150 minuter, 3500 kr",
  link: "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/balayage--1248652",
 },
 {
  heading: "Damklippning",
  description: "50 minuter, 760 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/damklippning--1248649",
 },
 {
  heading: "Herrklippning",
  description: "40 minuter, 650 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/herrklippning--1247905",
 },
 {
  heading: "Lugg",
  description: "15 minuter, 250 kr",
  link: "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/lugg--1366853",
 },
 {
  heading: "Herr klippning inkl skägg",
  description: "50 minuter, 950 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/herr-klippning-inkl-skagg-1261415",
 },
 {
  heading: "Slingor och färg & klippning långt hår",
  description: "150 minuter, 3000 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/slingor-och-f%C3%A4rg&klippning-l%C3%A5ngt-h%C3%A5r--1248655",
 },
 {
  heading: "Blowdry",
  description: "45 minuter, 550 kr",
  link: "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/blowdry--1248654",
 },
 {
  heading: "Hårförlängning tejp",
  description: "90 minuter, 1350 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/h%C3%A5rf%C3%B6rl%C3%A4ngning-tejp--1248653",
 },
 {
  heading: "Håruppsättning",
  description: "90 minuter, 1500 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/haruppsattning-1909981",
 },

 {
  heading: "Slingor och färg & klippning kort hår",
  description: "120 minuter, 2800 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/f%C3%A4rg-och-slingor-&klippning-kort-h%C3%A5r--1248651",
 },

 {
  heading: "Hår permanent",
  description: "130 minuter, 1700 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/har-permanent-1824270",
 },

 {
  heading: "Hår Spa & styling",
  description: "45 minuter, 1200 kr",
  link:
   "https://www.bokadirekt.se/boka-tjanst/cheveu-37684/har-spa-styling-2047602",
 },
];
