import React from 'react';
import Layout from '../components/global/Layout';
import Services from '../components/services/Services';

const tjänster = () => {
    return (
        <Layout>
            <Services />
        </Layout>
    );
};

export default tjänster;
